<template>
  <section class="invoice">
    <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
      <div class="content-wrapper">
        <div class="page-header">
        <h3 class="page-title">
            Bon de commande
        </h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <li class="breadcrumb-item">Mes Commandes</li>
            <li class="breadcrumb-item active" aria-current="page">facture</li>
            </ol>
        </nav>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card px-2">
                    <div class="card-body">
                        <div class="container-fluid">
                        <h3 class="text-right my-5"> <p>Référence :</p> MEB-{{getIndex}}</h3>
                        <hr> </div>
                        <div class="container-fluid d-flex justify-content-between">
                            <div class="col-lg-3 pl-0">
                                <p class="mt-5 mb-2">
                                <b>Administrateur</b>
                                </p>
                                <p>Mon échappée Belle,
                                <br>64 avenue Saint Exupéry,
                                <br>81600 GAILLAC.</p>
                            </div>
                            <div class="col-lg-3 pr-0">
                                <p class="mt-5 mb-2 text-right">
                                <b>Pour</b>
                                </p>
                                <span v-if="orders.deliveryinfos">
                                    <p class="text-right" v-if="orders.deliveryinfos.name">{{orders.deliveryinfos.name}}</p>
                                    <p class="text-right" v-if="orders.deliveryinfos.phone">{{orders.deliveryinfos.phone}}</p>
                                    <p class="text-right" v-if="orders.deliveryinfos.email">{{orders.deliveryinfos.email}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="container-fluid d-flex justify-content-between">
                        <div class="col-lg-3 pl-0">
                            <!-- <p class="mb-0 mt-5">Fait le: {{orders.dateorder}}</p>
                            <p>Pour le : {{formatDate(orders.datedelivery)}}</p> -->
                        </div>
                        </div>
                        <!-- tableau réservation -->
                        <!-- <div class="container-fluid mt-5 d-flex justify-content-center w-100"> 
                            <div class="table-responsive w-100">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                    <th>#</th>
                                    <th>Description</th>
                                    <th class="text-left">Date</th>
                                    <th class="text-left">Durée</th>
                                    <th class="text-left">Débute à</th>
                                    <th class="text-left">Se termine à</th>
                                    <th class="text-right">Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.appointments" :key="purchaseId">
                                        <td class="text-left">{{purchaseId+1}}</td>
                                        <td class="text-left">{{purchase.service.name}}</td>
                                        <td class="text-left">{{today(purchase.start_hour)}}</td>
                                        <td class="text-left">{{calculTimes(purchase.service.duration)}}</td>
                                        <td class="text-left">{{toTime(purchase.start_hour)}}</td>
                                        <td class="text-left">{{toTime(purchase.end_hour)}}</td>
                                        <td class="text-right"> ${{purchase.service.price}} </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div> -->

                        <!-- tableau d'achat -->
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-if="orders.purchases && orders.purchases[0].product != null">
                            <div class="table-responsive">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                    <th>#</th>
                                    <th>Description</th>
                                    <th class="text-left">Prix unitaire</th>
                                    <th class="text-left">Quantité achetée</th>
                                    <th class="text-left">Prix total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                    <td>{{purchaseId + 1}}</td>
                                    <td class="text-left">{{purchase.product.name}}</td>
                                    <td class="text-left">{{purchase.product.price *1.2}}€</td>
                                    <td class="text-left">{{purchase.quantity}}</td>
                                    <td class="text-left">{{purchase.quantity * (purchase.product.price *1.2) }}€</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- tableau de carte cadeau (vouchers) -->
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-else-if="orders.purchases && orders.purchases[0].voucher != null">
                            <div class="table-responsive">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                        <th>#</th>
                                        <th>Envoyeur</th>
                                        <th class="text-left">Destinataire</th>
                                        <th class="text-left">Bon d'achat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                        <td class="text-left">{{purchaseId + 1}}</td>
                                        <td class="text-left">{{sender}}</td>
                                        <td class="text-left">{{receiver}}</td>
                                        <td class="text-left">{{purchase.voucher.price}}€</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="container-fluid mt-5 w-100">
                        <h4 class="text-right mb-5">Total : ${{total}}</h4>
                        <hr> </div>
                        <div class="container-fluid w-100">
                            <span class="btn btn-outline-danger float-left mt-4 ml-2" @click="deleteOrder()" >
                                <i class="fa fa-trash"></i> Supprimer
                            </span>
                            <span v-if="orders.status !=='cancelled'" class="btn btn-danger float-left mt-4 ml-2" @click="cancelOrder()" >
                                <i class="fa fa-cancel"></i> Annuler achat
                            </span>
                            <!-- <span class="btn btn-primary float-right mt-4 ml-2" @click="print()">
                                <i class="mdi mdi-printer mr-1"></i> Imprimer
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </section>
</template>

<script>
import axios from 'axios'
import { dateMixin } from '../mixins/date'
import pageLoader from '../../../components/pageLoader.vue'
export default {
  name: 'ordersDetails',
  mixins:[dateMixin],
  components:{
        pageLoader
  },
  data () {
      return {
          ulid: '',
          orders: '',
          loadershow:true,
          allOrder:null,
          purchases: []
      }
  },
  computed:{
      total: function(){
          var tota = 0;
          this.purchases.forEach(element => {
              if(element.service){
                  tota = tota + element.service.price
              }
              if(element.product){
                  tota = tota + (element.product.price * 1.2) * element.quantity
              }
              if(element.voucher){
                  tota = tota + element.voucher.price * element.quantity
              }
          }); 
          return tota
      },
      receiver(){
          if(this.orders.purchases[0].voucher){
              return this.orders.purchases[0].voucher.receiver.firstname +' '+ this.orders.purchases[0].voucher.receiver.lastname
          }else{
              return 'hi'
          }
      },
      sender(){
          if(this.orders.purchases[0].voucher){
              return this.orders.purchases[0].voucher.sender.firstname +' '+ this.orders.purchases[0].voucher.sender.lastname
          }else{
              return 'hi'
          }
      },
      getIndex(){
            let index = 0
            if (this.allOrder) {
                index = this.allOrder.findIndex( object => {
                    return object.ulid == this.ulid
                })
            }
            return index
      }


  },
  methods: {
      getOrdersDetails(ulid){
          axios.get(`orders/${ulid}`)
          .then(resGetOrdersDetails => {
              this.orders=resGetOrdersDetails.data
              this.purchases=resGetOrdersDetails.data.purchases
              this.loadershow =false
          })
          .catch(errOrdersDetails => console.log(errOrdersDetails))
      },
      validateOrder() {
          axios.post(`myorders/validate/${this.ulid}`)
          .then(resValidateOrder => console.log({resValidateOrder}))
          .catch(errValidateOrder => console.log(errValidateOrder))
      },
      
      toTime(time){
          return time.substr(11, 5)
      },
      today(time){
          return time.substr(0, 10)
      },
      cancelOrder(){
        axios.put(`orders/${this.ulid}`,{
            user : this.orders.user.ulid,
            status:"cancelled"
        })
        .then(resCancelOrder => {
            resCancelOrder
            alert("L'achat a été annulé")
            this.$router.go(-1)
        })
        .catch(errCancelOrder => {
            console.log({errCancelOrder});
        })
      },
      deleteOrder(){
          this.$swal({
                title: 'Voulez-vous supprimer cette réservation?',
                showCancelButton: true,
                confirmButtonColor: '#000',
                cancelButtonColor: 'rgb(162, 162, 162)',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Fermer'
            })
            .then((result) => {
                if (result.value) {
                this.loadershow = true;
                axios.delete('reservations/'+this.ulid)
                    .then(()=>{
                    this.$router.go(-1)
                    })
                    .catch()
                }else{
                    this.loadershow=false
                }
            })
      }
  },
  mounted () {
      this.getOrdersDetails(this.ulid)
    //   this.getAllOrders()
  },
  created () {
      this.ulid= this.$route.params.ulid
  }
}
</script>
